import newsApi from '../api/newsApi';

export function atnCheckNewsToken(data) {
  return newsApi.checkNewsToken(data);
}

export function atnCreateNews(data) {
  return newsApi.createNews(data);
}

export function atnGetNewsList(data) {
  return newsApi.getNewsList(data);
}

export function atnGetEditNewsData(data) {
  return newsApi.getEditNewsData(data);
}

export function atnUpdateNewsBasicData(data) {
  return newsApi.updateNewsBasicData(data);
}

export function atnUpdateNewsContent(data) {
  return newsApi.updateNewsContent(data);
}