import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import classes from '../input.module.css';

const TextAreaInput = ({
  rows,
  label = "",
  placeholder = "",
  name = "",
  value = "",
  isRequired = false,
  handleChange,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputLabel}>
        {isRequired ? <>{label} <span className={classes.requiredMessage}>{t('form.requiredField')}</span></> : <>{label}</>}
      </div>
      <TextArea
        rows={rows || 4}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={e => handleChange(e)}
        {...otherProps}
      />
    </div>
  );
};

export default TextAreaInput;
