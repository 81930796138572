import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Modal } from 'antd';

import { generateRandomString, getImageDomain, getImageFormat } from 'helpers/utils';
import NewsForm from '../components/NewsForm';
import { atnUploadNewsThumbnail, atnUploadNewsImage } from 'actions/fileActions';
import { atnCheckNewsToken, atnCreateNews } from 'actions/newsActions';

const CreateNews = () => {
  const { t } = useTranslation();

  const initNewsData = {
    token: "",
    type: "news",
    title: "",
    metaDescription: "",
    platforms: [],
    thumbnail: {
      file: null,
      url: "",
    },
  };

  const initContentElements = [];

  const [loading, setLoading] = useState(false);

  const uploadNewsImage = imageData => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", imageData.image);
      formData.set("path", imageData.path);

      atnUploadNewsImage(formData)
      .then(uploadImageResult => {
        if (uploadImageResult.success) {
          resolve(uploadImageResult.data);
        } else {
          reject();
        }
      });
    });
  };

  const showErrorModal = message => {
    if (message === "Unauthorized") {

      // import login pop up and set state

      return Modal.error({
        title: t("modal.error"),
        content: message,
      });

    } else {
      return Modal.error({
        title: t("modal.error"),
        content: message,
      });
    }
  }

  const handleSubmit = (newsData, contentElements) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 30000);

    atnCheckNewsToken({
      token: newsData.token
    })
    .then(checkTokenResult => {
      if (checkTokenResult.success) {
        if (checkTokenResult.data && checkTokenResult.data.id) {
          setLoading(false);
          showErrorModal(t("modal.duplicateToken"));
        } else {
          let thumbnailData = new FormData();
          thumbnailData.append("image", newsData.thumbnail.file);
          thumbnailData.set("token", newsData.token);
          thumbnailData.set("type", "news");

          atnUploadNewsThumbnail(thumbnailData)
          .then(uploadThumbnailResult => {
            if (uploadThumbnailResult.success) {
              let imageUploadFuncArray = [];
              let newsImages = [];
              let formattedContentElement = [];
              let tempElement;
              let tempImage;
              let imageName;

              for (let i = 0; i < contentElements.length; i++) {
                tempElement = JSON.parse(JSON.stringify(contentElements[i]));

                if (tempElement.type === "image") {
                  for (let j = 0; j < tempElement.images.length; j++) {
                    tempImage = tempElement.images[j];
                    imageName = generateRandomString(8);

                    if (contentElements[i].images[j].file) {
                      imageUploadFuncArray.push(uploadNewsImage({
                        image: contentElements[i].images[j].file,
                        path: "news/" + newsData.token + "/" + imageName,
                      }));
                    }

                    newsImages.push({
                      original: getImageDomain() + "news/" + newsData.token + "/" + imageName + "-hd" + getImageFormat(),
                      thumbnail: getImageDomain() + "news/" + newsData.token + "/" + imageName + getImageFormat(),
                    });

                    tempElement.images[j] = {
                      imageName: imageName,
                      format: getImageFormat(),
                      caption: tempImage.caption,
                      url: getImageDomain() + "news/" + newsData.token + "/" + imageName + getImageFormat(),
                      imageAltText: tempImage.imageAltText || newsData.title,
                    };
                  }

                  formattedContentElement.push(tempElement);
                } else {
                  formattedContentElement.push(tempElement);
                }
              }

              let createNewsData = {
                token: newsData.token,
                type: newsData.type,
                title: newsData.title,
                metaDescription: newsData.metaDescription,
                platforms: newsData.platforms,
                thumbnailUrl: uploadThumbnailResult.data.url,
                formattedContentElement: formattedContentElement,
                newsImages: newsImages
              };

              atnCreateNews(createNewsData)
              .then(createNewsResult => {
                if (createNewsResult.success) {
                  if (imageUploadFuncArray.length > 0) {
                    Promise.all(imageUploadFuncArray)
                    .then(() => {
                      setLoading(false);

                      Modal.success({
                        content: t("modal.success"),
                      });
                    });
                  } else {
                    setLoading(false);

                    Modal.success({
                      content: t("modal.success"),
                    });
                  }
                } else {
                  setLoading(false);
                  showErrorModal(createNewsResult.message);
                }
              });
            } else {
              setLoading(false);
              showErrorModal(uploadThumbnailResult.message);
            }
          });
        }
      } else {
        setLoading(false);
        showErrorModal(checkTokenResult.message);
      }
    });
  };

  return (
    <>
      <h1>{t('news.createNews')}</h1>
      <Divider />
      <NewsForm
        initNewsData={initNewsData}
        initContentElements={initContentElements}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateNews;
