import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import classes from '../select.module.css';

const MultipleSelect = ({
  label = "",
  placeholder = "",
  name = "",
  value = "",
  // defaultValue = "",
  options = [],
  isRequired = false,
  handleChange,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleValueChange = newValue => {
    handleChange({
      target: {
        name: name,
        value: newValue
      }
    });
  };

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputLabel}>
        {isRequired ? <>{label} <span className={classes.requiredMessage}>{t('form.requiredField')}</span></> : <>{label}</>}
      </div>
      <Select
        mode="multiple"
        allowClear
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleValueChange}
        style={{ width: "100%" }}
        {...otherProps}
      >
        {options.map(item => (
          <Option key={item.value} value={item.value}>{item.label}</Option>
        ))}
      </Select>
    </div>
  );
};

export default MultipleSelect;
