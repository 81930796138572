import api from './api';

class fileAPI {
  static uploadArticleThumbnail(data) {
    return api.filePost('/uploadArticleThumbnail', data);
  }

  static uploadArticleImage(data) {
    return api.filePost('/uploadArticleImage', data);
  }

  static uploadPostImage(data) {
    return api.filePost('/uploadPostImage', data);
  }
}

export default fileAPI;
