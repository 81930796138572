import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, Button } from 'antd';
import 'styles/login.css';

import { atnLogin } from 'actions/authActions';

const Login = ({
  history
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    loginName: "",
    password: "",
  });

  const [errorData, setErrorData] = useState({
    isError: false,
    errorMessage: "",
  });

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    if (!formData.loginName) {
      return setErrorData({
        isError: true,
        errorMessage: t('login.inputUsername'),
      });
    }

    if (!formData.password) {
      return setErrorData({
        isError: true,
        errorMessage: t('login.inputPassword'),
      });
    }

    atnLogin(formData)
    .then(loginResult => {
      if (!loginResult) {
        return;
      }

      const { success, data, message } = loginResult;
      if (success) {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('role', data.role);

        let checkTokenTimes = 0;

        const checkToken = setInterval(() => {
          checkTokenTimes += 1;

          if (checkTokenTimes > 20) {
            clearInterval(checkToken);
            alert("please login again");
          }

          if (sessionStorage.getItem("token") && sessionStorage.getItem("role")) {
            clearInterval(checkToken);

            history.push("/dashboard");
          }
        }, 200);
      } else {
        setErrorData({
          isError: true,
          errorMessage: message === "invalid data" ? t('login.wrongUsernameOrPassword') : message,
        });
      }
    });
  };

  return (
    <div className="login-container">
      {/* <div>login</div> */}

      <Row>
        <Col span={8} offset={8} className="login-form-container">
          {errorData.isError &&
            <div className="login-form-error-message">
              {errorData.errorMessage}
            </div>
          }
          <div className="login-input-container">
            <div className="login-label">{t('login.username')}</div>
            <Input
              type="text"
              placeholder={t('login.username')}
              name="loginName"
              value={formData.loginName}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="login-input-container">
            <div className="login-label">{t('login.password')}</div>
            <Input
              type="password"
              placeholder={t('login.password')}
              name="password"
              value={formData.password}
              onChange={e => handleChange(e)}
            />
          </div>
          <Button type="primary" onClick={() => handleLogin()} className="login-button">
            {t('login.login')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
