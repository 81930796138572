import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Modal } from 'antd';

import { generateRandomString, getImageDomain, getImageFormat } from 'helpers/utils';
import ArticleBasicData from '../components/ArticleBasicData';
import ArticleContent from '../components/ArticleContent';
import BasicSelect from 'components/select/BasicSelect';
import SearchInput from 'components/input/SearchInput';
import { atnUploadArticleThumbnail, atnUploadArticleImage } from 'actions/fileActions';
import {
  atnGetEditArticleData,
  atnUpdateArticleBasicData,
  atnUpdateArticleContent
} from 'actions/articleActions';

import constants from 'constants.json';
import classes from 'styles/article.module.css';

const EditArticle = () => {
  const { t } = useTranslation();

  const articleSearchTypes = [];

  for (let i = 0; i < constants.articleSearchTypes.length; i++) {
    articleSearchTypes.push({
      "label": t(`articleSearchType.${constants.articleSearchTypes[i].label}`),
      "value": constants.articleSearchTypes[i].value
    });
  }

  const languages = [];

  for (let i = 0; i < constants.languages.length; i++) {
    languages.push({
      "label": t(`languages.${constants.languages[i].label}`),
      "value": constants.languages[i].value
    });
  }

  const [showArticleData, setShowArticleData] = useState({
    show: false,
    type: ""
  });
  const [showErrorMessage, setErrorMessage] = useState(false);

  const [searchData, setSearchData] = useState({
    type: "basic",
    language: "zh-HK",
  });

  const [initBasicData, setInitBasicData] = useState({
    id: "",
    token: "",
    type: "",
    platforms: [],
    status: "",
  });

  const [initContentData, setInitContentData] = useState({
    token: "",
    articleDetailId: "",
    metaDescription: "",
    title: "",
    contentData: [],
    thumbnail: {},
    language: "",
  });

  const [loading, setLoading] = useState(false);

  const handleSearchDataChange = e => {
    const { name, value } = e.target;

    setSearchData(prevState => ({
      ...prevState,
      [name]: value
    }))
  };

  const handleArticleSearch = value => {
    if (!value) {
      return;
    }

    let submitData = {
      ...searchData,
      articleId: value
    };

    atnGetEditArticleData(submitData)
    .then(getArticleResult => {
      if (getArticleResult.success) {
        setErrorMessage(false);

        let resultData = getArticleResult.data;

        if (resultData.dataType === "basic") {
          setInitBasicData({
            id: resultData.id,
            token: resultData.token,
            type: resultData.type,
            platforms: resultData.platforms,
            status: resultData.status,
          });
        } else if (resultData.dataType === "content") {
          setInitContentData({
            token: resultData.token,
            articleDetailId: resultData.articleDetailId,
            metaDescription: resultData.metaDescription,
            title: resultData.title,
            contentData: resultData.contentData || [],
            thumbnail: {
              file: null,
              url: resultData.thumbnail,
            },
            language: resultData.language,
          });
        }

        setShowArticleData({
          show: true,
          type: resultData.dataType
        });
      } else {
        setErrorMessage(true);
      }
    });
  };

  const uploadArticleImage = imageData => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", imageData.image);
      formData.set("path", imageData.path);

      atnUploadArticleImage(formData)
      .then(uploadImageResult => {
        if (uploadImageResult.success) {
          resolve(uploadImageResult.data);
        } else {
          reject();
        }
      });
    });
  };

  const showErrorModal = message => {
    if (message === "Unauthorized") {

      // import login pop up and set state

      return Modal.error({
        title: t("modal.error"),
        content: message,
      });

    } else {
      return Modal.error({
        title: t("modal.error"),
        content: message,
      });
    }
  }

  const handleBasicDataSubmit = articleData => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 30000);

    atnUpdateArticleBasicData(articleData)
    .then(updateResult => {
      if (updateResult.success) {
        setLoading(false);

        Modal.success({
          content: t("modal.success"),
        });
      } else {
        setLoading(false);
        showErrorModal(updateResult.message);
      }
    });
  };

  const handleArticleContentSubmit = (articleData, contentElements) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 30000);

    const updateOtherData = () => {
      let imageUploadFuncArray = [];
      let articleImages = [];
      let formattedContentElement = [];
      let tempElement;
      let tempImage;
      let imageName;

      for (let i = 0; i < contentElements.length; i++) {
        tempElement = JSON.parse(JSON.stringify(contentElements[i]));

        if (tempElement.type === "image") {
          for (let j = 0; j < tempElement.images.length; j++) {
            tempImage = tempElement.images[j];
            imageName = tempImage.imageName || generateRandomString(8);

            if (contentElements[i].images[j].isNew && contentElements[i].images[j].file) {
              imageUploadFuncArray.push(uploadArticleImage({
                image: contentElements[i].images[j].file,
                path: "articles/" + articleData.token + "/" + imageName,
              }));
            }

            articleImages.push({
              original: getImageDomain() + "articles/" + articleData.token + "/" + imageName + "-hd" + getImageFormat(),
              thumbnail: getImageDomain() + "articles/" + articleData.token + "/" + imageName + getImageFormat(),
            });

            tempElement.images[j] = {
              imageName: imageName,
              format: getImageFormat(),
              caption: tempImage.caption,
              url: getImageDomain() + "articles/" + articleData.token + "/" + imageName + getImageFormat(),
              imageAltText: tempImage.imageAltText || articleData.title,
            };
          }

          formattedContentElement.push(tempElement);
        } else {
          formattedContentElement.push(tempElement);
        }
      }

      let updateArticleData = {
        articleDetailId: articleData.articleDetailId,
        title: articleData.title,
        metaDescription: articleData.metaDescription,
        thumbnailUrl: "articles/" + articleData.token + "/thumbnail" + getImageFormat(),
        formattedContentElement: formattedContentElement,
        articleImages: articleImages
      };

      atnUpdateArticleContent(updateArticleData)
      .then(updateArticleResult => {
        if (updateArticleResult.success) {
          if (imageUploadFuncArray.length > 0) {
            Promise.all(imageUploadFuncArray)
            .then(() => {
              setLoading(false);

              // Modal.success({
              //   content: t("modal.success"),
              // });
              window.location.reload();
            });
          } else {
            setLoading(false);

            // Modal.success({
            //   content: t("modal.success"),
            // });
            window.location.reload();
          }
        } else {
          setLoading(false);
          showErrorModal(updateArticleResult.message);
        }
      });
    };

    if (articleData.thumbnail.isNew) {
      const thumbnailData = new FormData();
      thumbnailData.append("image", articleData.thumbnail.file);
      thumbnailData.set("token", articleData.token);
      thumbnailData.set("type", "articles");

      atnUploadArticleThumbnail(thumbnailData)
      .then(() => {
        updateOtherData();
      });
    } else {
      updateOtherData();
    }
  };

  return (
    <>
      <h1>{t('article.editArticle')}</h1>
      <Divider />

      <div className={classes.editArticleSearchSectionBlock}>
        <BasicSelect
          label=""
          placeholder=""
          name="type"
          value={searchData.type}
          options={articleSearchTypes}
          handleChange={handleSearchDataChange}
        />
      </div>
      <div className={classes.editArticleSearchSectionBlock}>
        <BasicSelect
          label=""
          placeholder=""
          name="language"
          value={searchData.language}
          options={languages}
          handleChange={handleSearchDataChange}
        />
      </div>
      <div className={classes.editArticleSearchSectionBlock}>
        <SearchInput
          placeholder={t('article.articleId')}
          handleSearch={handleArticleSearch}
          allowClear
          enterButton
        />
      </div>

      <Divider />

      {showErrorMessage &&
        <div>
          No data
        </div>
      }

      {showArticleData.show && showArticleData.type === "basic" &&
        <ArticleBasicData
          isEditArticle={true}
          initBasicData={initBasicData}
          loading={loading}
          handleSubmit={handleBasicDataSubmit}
        />
      }

      {showArticleData.show && showArticleData.type === "content" &&
        <ArticleContent
          initContentData={initContentData}
          loading={loading}
          handleSubmit={handleArticleContentSubmit}
        />
      }
    </>
  );
};

export default EditArticle;
