import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../auth/authenticator';

const ProtectedRoute = ({
  component: Component,
  role,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (auth.loggedIn()) {
        if (auth.loginRole() !== role) {
          return (
            <Redirect
              to={{
                pathname: '/404',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }

        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
            },
          }}
        />
      );
    }}
  />
);

export default ProtectedRoute;
