import React from 'react';
import { Layout } from 'antd';
import auth from 'auth/authenticator';
import MasterSideMenu from './MasterSideMenu';
import AdminSideMenu from './AdminSideMenu';
import EditorSideMenu from './EditorSideMenu';

const { Sider } = Layout;

const SideMenu = () => {
  const loginRole = auth.loginRole();

  return (
    <Sider className="site-menu-container" trigger={null}>
      <div className="logo">
        LOGO
      </div>

      {loginRole === "master" &&
        <MasterSideMenu />
      }

      {loginRole === "admin" &&
        <AdminSideMenu />
      }

      {loginRole === "editor" &&
        <EditorSideMenu />
      }
    </Sider>
  );
}

export default SideMenu;