import React from 'react';
import classnames from 'classnames';
import { Checkbox } from 'antd';

import classes from '../checkbox.module.css';

const BasicCheckbox = ({
  label = "",
  name = "",
  value = false,
  handleChange,
  hasPadding = true,
  ...otherProps
}) => {
  const handleValueChange = e => {
    handleChange({
      target: {
        name: name,
        value: e.target.checked
      }
    });
  };

  return (
    <div className={classnames({ [classes.inputContainer]: hasPadding }, { [classes.inputContainerNoPadding]: !hasPadding })}>
      <Checkbox
        name={name}
        checked={value}
        onChange={handleValueChange}
        {...otherProps}
      >
        <span className={classes.inputLabel}>
          {label}
        </span>
      </Checkbox>
    </div>
  );
};

export default BasicCheckbox;
