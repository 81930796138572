import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { atnGetNewsList } from 'actions/newsActions';

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: '10%',
  },
  {
    title: 'Token',
    dataIndex: 'token',
    width: '20%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%',
  },
  {
    title: 'Title',
    dataIndex: 'newsDetails',
    render: newsDetails => newsDetails[0].title,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10%',
  },
];

const NewsList = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetch({ pagination });
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);

    // fetch({
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   pagination,
    //   ...filters,
    // });
  };

  const fetch = (params = {}) => {
    setLoading(true);

    atnGetNewsList(params)
    .then(result => {
      setLoading(false);
      setData(result.data);
    });
  };

  return (
    <div>
      <h1>{t('news.newsList')}</h1>
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default NewsList;
