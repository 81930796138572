import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider, Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import SearchInput from 'components/input/SearchInput';
import { generateRandomString, getImageDomain, getImageFormat } from 'helpers/utils';
import PostForm from '../components/PostForm';
import { atnUploadPostImage } from 'actions/fileActions';
import { atnGetPostData, atnUpdatePost } from 'actions/postActions';

import classes from 'styles/post.module.css';

const EditPost = () => {
  const { t } = useTranslation();

  const [initPostData, setInitPostData] = useState({
    id: "",
    token: "",
    content: "",
    images: [],
    // imageData: [],
    videoSource: "",
    links: [],
    status: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);

  const handlePostSearch = value => {
    if (!value) {
      return;
    }

    setShowPostDetails(false);

    let submitData = {
      postId: value
    };

    atnGetPostData(submitData)
    .then(getPostResult => {
      if (getPostResult.success) {
        setErrorMessage(false);

        let resultData = getPostResult.data;

        setInitPostData(resultData);

        setShowPostDetails(true);
      } else {
        setErrorMessage(true);
      }
    });
  };

  const uploadPostImage = imageData => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", imageData.image);
      formData.set("path", imageData.path);
      formData.set("isFirstImage", imageData.isFirstImage);

      atnUploadPostImage(formData)
      .then(uploadImageResult => {
        if (uploadImageResult.success) {
          resolve(uploadImageResult.data);
        } else {
          reject();
        }
      });
    });
  };

  const showErrorModal = message => {
    if (message === "Unauthorized") {

      // import login pop up and set state

      return Modal.error({
        title: t("modal.error"),
        content: message,
      });

    } else {
      return Modal.error({
        title: t("modal.error"),
        content: message,
      });
    }
  }

  const handleSubmit = postData => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 30000);

    let token = postData.token;

    let imageUploadFuncArray = [];
    let images = [];
    let imageData = [];
    let imageName;
    let imageFormat;

    for (let i = 0; i < postData.images.length; i++) {
      imageName = postData.images[i].imageName || generateRandomString(8);
      imageFormat = postData.images[i].format || getImageFormat();

      if (postData.images[i].isNew && postData.images[i].file) {
        imageUploadFuncArray.push(uploadPostImage({
          image: postData.images[i].file,
          path: "posts/" + token + "/" + imageName,
          isFirstImage: i === 0
        }));
      }

      images.push({
        imageName: imageName,
        format: imageFormat,
        url: getImageDomain() + "posts/" + token + "/" + imageName + imageFormat,
        imageAltText: "",
      });

      imageData.push({
        original: getImageDomain() + "posts/" + token + "/" + imageName + "-hd" + imageFormat,
        thumbnail: getImageDomain() + "posts/" + token + "/" + imageName + imageFormat,
      });
    }

    let updatePostData = {
      postId: postData.id,
      token: token,
      content: postData.content,
      images: images,
      imageData: imageData,
      videoSource: postData.videoSource,
      links: postData.links,
      status: postData.status,
    };

    atnUpdatePost(updatePostData)
    .then(updatePostResult => {
      if (updatePostResult.success) {
        if (imageUploadFuncArray.length > 0) {
          Promise.all(imageUploadFuncArray)
          .then(() => {
            setLoading(false);

            Modal.success({
              content: t("modal.success"),
            });
          });
        } else {
          setLoading(false);

          Modal.success({
            content: t("modal.success"),
          });
        }
      } else {
        setLoading(false);
        showErrorModal(updatePostResult.message);
      }
    });
  };

  return (
    <>
      <h1>{t('post.editPost')}</h1>
      <div className={classes.postSearchField}>
        <SearchInput
          placeholder={t('post.postId')}
          handleSearch={handlePostSearch}
          allowClear
          enterButton
        />
      </div>
      <Divider />

      {showErrorMessage &&
        <div>
          No data
        </div>
      }

      {showPostDetails &&
        <PostForm
          initPostData={initPostData}
          loading={loading}
          handleSubmit={handleSubmit}
        />
      }
    </>
  );
};

export default EditPost;