import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Modal } from 'antd';

import { generateRandomString, getImageDomain, getImageFormat } from 'helpers/utils';
import ArticleForm from '../components/ArticleForm';
import { atnUploadArticleThumbnail, atnUploadArticleImage } from 'actions/fileActions';
import { atnCheckArticleToken, atnCreateArticle } from 'actions/articleActions';

const CreateArticle = () => {
  const { t } = useTranslation();

  const initArticleData = {
    token: "",
    type: "article",
    title: "",
    metaDescription: "",
    platforms: [],
    thumbnail: {
      file: null,
      url: "",
    },
  };

  const initContentElements = [];

  const [loading, setLoading] = useState(false);

  const uploadArticleImage = imageData => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", imageData.image);
      formData.set("path", imageData.path);

      atnUploadArticleImage(formData)
      .then(uploadImageResult => {
        if (uploadImageResult.success) {
          resolve(uploadImageResult.data);
        } else {
          reject();
        }
      });
    });
  };

  const showErrorModal = message => {
    if (message === "Unauthorized") {

      // import login pop up and set state

      return Modal.error({
        title: t("modal.error"),
        content: message,
      });

    } else {
      return Modal.error({
        title: t("modal.error"),
        content: message,
      });
    }
  }

  const handleSubmit = (articleData, contentElements) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 30000);

    atnCheckArticleToken({
      token: articleData.token
    })
    .then(checkTokenResult => {
      if (checkTokenResult.success) {
        if (checkTokenResult.data && checkTokenResult.data.id) {
          setLoading(false);
          showErrorModal(t("modal.duplicateToken"));
        } else {
          let thumbnailData = new FormData();
          thumbnailData.append("image", articleData.thumbnail.file);
          thumbnailData.set("token", articleData.token);
          thumbnailData.set("type", "articles");

          atnUploadArticleThumbnail(thumbnailData)
          .then(uploadThumbnailResult => {
            if (uploadThumbnailResult.success) {
              let imageUploadFuncArray = [];
              let articleImages = [];
              let formattedContentElement = [];
              let tempElement;
              let tempImage;
              let imageName;

              for (let i = 0; i < contentElements.length; i++) {
                tempElement = JSON.parse(JSON.stringify(contentElements[i]));

                if (tempElement.type === "image") {
                  for (let j = 0; j < tempElement.images.length; j++) {
                    tempImage = tempElement.images[j];
                    imageName = generateRandomString(8);

                    if (contentElements[i].images[j].file) {
                      imageUploadFuncArray.push(uploadArticleImage({
                        image: contentElements[i].images[j].file,
                        path: "articles/" + articleData.token + "/" + imageName,
                      }));
                    }

                    articleImages.push({
                      original: getImageDomain() + "articles/" + articleData.token + "/" + imageName + "-hd" + getImageFormat(),
                      thumbnail: getImageDomain() + "articles/" + articleData.token + "/" + imageName + getImageFormat(),
                    });

                    tempElement.images[j] = {
                      imageName: imageName,
                      format: getImageFormat(),
                      caption: tempImage.caption,
                      url: getImageDomain() + "articles/" + articleData.token + "/" + imageName + getImageFormat(),
                      imageAltText: tempImage.imageAltText || articleData.title,
                    };
                  }

                  formattedContentElement.push(tempElement);
                } else {
                  formattedContentElement.push(tempElement);
                }
              }

              let createArticleData = {
                token: articleData.token,
                type: articleData.type,
                title: articleData.title,
                metaDescription: articleData.metaDescription,
                platforms: articleData.platforms,
                thumbnailUrl: uploadThumbnailResult.data.url,
                formattedContentElement: formattedContentElement,
                articleImages: articleImages
              };

              atnCreateArticle(createArticleData)
              .then(createArticleResult => {
                if (createArticleResult.success) {
                  if (imageUploadFuncArray.length > 0) {
                    Promise.all(imageUploadFuncArray)
                    .then(() => {
                      setLoading(false);

                      Modal.success({
                        content: t("modal.success"),
                      });
                    });
                  } else {
                    setLoading(false);

                    Modal.success({
                      content: t("modal.success"),
                    });
                  }
                } else {
                  setLoading(false);
                  showErrorModal(createArticleResult.message);
                }
              });
            } else {
              setLoading(false);
              showErrorModal(uploadThumbnailResult.message);
            }
          });
        }
      } else {
        setLoading(false);
        showErrorModal(checkTokenResult.message);
      }
    });
  };

  return (
    <>
      <h1>{t('article.createArticle')}</h1>
      <Divider />
      <ArticleForm
        initArticleData={initArticleData}
        initContentElements={initContentElements}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateArticle;
