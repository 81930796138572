import React from 'react';

const EditGame = () => {

  return (
    <h1>Edit Game</h1>
  );
};

export default EditGame;
