class Auth {
  static loggedIn() {
    // return !!sessionStorage.token;
    return sessionStorage.token && sessionStorage.role;
  }

  static loginRole() {
    return sessionStorage.role;
  }

  static logOut() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
  }
}

export default Auth;
