import articleApi from '../api/articleApi';

export function atnCheckArticleToken(data) {
  return articleApi.checkArticleToken(data);
}

export function atnCreateArticle(data) {
  return articleApi.createArticle(data);
}

export function atnGetArticleList(data) {
  return articleApi.getArticleList(data);
}

export function atnGetEditArticleData(data) {
  return articleApi.getEditArticleData(data);
}

export function atnUpdateArticleBasicData(data) {
  return articleApi.updateArticleBasicData(data);
}

export function atnUpdateArticleContent(data) {
  return articleApi.updateArticleContent(data);
}