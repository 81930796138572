import fileAPI from '../api/fileApi';

export function atnUploadArticleThumbnail(data) {
  return fileAPI.uploadArticleThumbnail(data);
}

export function atnUploadArticleImage(data) {
  return fileAPI.uploadArticleImage(data);
}

export function atnUploadNewsThumbnail(data) {
  return fileAPI.uploadArticleThumbnail(data);
}

export function atnUploadNewsImage(data) {
  return fileAPI.uploadArticleImage(data);
}

export function atnUploadPostImage(data) {
  return fileAPI.uploadPostImage(data);
}
