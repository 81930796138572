import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import ScrollToTop from './router/ScrollToTop';

import './styles/main.css';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div>
          <Router />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  )
};

export default App;
