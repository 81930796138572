import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from 'containers/layout/SideMenu/index';
import Header from 'containers/layout/Header';
import ProtectedRoute from './ProtectedRoute';

import NotFound from 'pages/NotFound';
import Dashboard from 'pages/Dashboard/index';

import GameList from 'pages/Game/GameList/index';
import CreateGame from 'pages/Game/CreateGame/index';
import EditGame from 'pages/Game/EditGame/index';

import NewsList from 'pages/News/NewsList/index';
import CreateNews from 'pages/News/CreateNews/index';
import EditNews from 'pages/News/EditNews/index';

import PostList from 'pages/Post/PostList/index';
import CreatePost from 'pages/Post/CreatePost/index';
import EditPost from 'pages/Post/EditPost/index';

import ArticleList from 'pages/Article/ArticleList/index';
import CreateArticle from 'pages/Article/CreateArticle/index';
import EditArticle from 'pages/Article/EditArticle/index';

const { Content } = Layout;

const WrappedRoutes = () => {
  return (
    <Layout>
      <SideMenu />
      <Layout className="content-layout">
        <Header />
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          <Switch>
            <Route exact path='/dashboard' component={Dashboard} />

            <Route exact path='/gameList' component={GameList} />
            <Route exact path='/createGame' component={CreateGame} />
            <Route exact path='/editGame' component={EditGame} />

            <Route exact path='/newsList' component={NewsList} />
            <Route exact path='/createNews' component={CreateNews} />
            <Route exact path='/editNews' component={EditNews} />

            <Route exact path='/postList' component={PostList} />
            <Route exact path='/createPost' component={CreatePost} />
            <Route exact path='/editPost' component={EditPost} />

            <Route exact path='/articleList' component={ArticleList} />
            <Route exact path='/createArticle' component={CreateArticle} />
            <Route exact path='/editArticle' component={EditArticle} />

            <ProtectedRoute exact component={NotFound} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default WrappedRoutes;
