import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import WrappedRoutes from './WrappedRoutes';
import history from '../helpers/history';

// common route
import Login from '../pages/Login/index';
import NotFound from '../pages/NotFound';

const AppRouter = () => {
  return (
    <MainWrapper>
      <main>
        <BrowserRouter history={history}>
          <Switch>
            <Route exact path='/404' component={NotFound} />
            <Route exact path='/' component={Login} />
            <Route exact path='/login' component={Login} />
            <Route path="/" component={WrappedRoutes} />
          </Switch>
        </BrowserRouter>
      </main>
    </MainWrapper>
  );
};

export default AppRouter;
