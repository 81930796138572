import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import MultipleSelect from 'components/select/MultipleSelect';

import constants from 'constants.json';

import classes from 'styles/article.module.css';
import 'styles/serverStyle.css';

const ArticleBasicData = ({
  isEditArticle = false,
  initBasicData,
  loading,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const articleTypes = [];

  for (let i = 0; i < constants.articleTypes.length; i++) {
    articleTypes.push({
      "label": t(`articleType.${constants.articleTypes[i].label}`),
      "value": constants.articleTypes[i].value
    });
  }

  const articleStatusTypes = [];

  for (let i = 0; i < constants.articleStatusTypes.length; i++) {
    articleStatusTypes.push({
      "label": t(`articleStatusType.${constants.articleStatusTypes[i].label}`),
      "value": constants.articleStatusTypes[i].value
    });
  }

  const [articleData, setArticleData] = useState(initBasicData);

  useEffect(() => {
    setArticleData(initBasicData);
  }, [initBasicData]);

  const handleArticleDataChange = e => {
    const { name, value } = e.target;

    setArticleData(prevState => ({
      ...prevState,
      [name]: name === "token" ? value.replace(/\s/g, '').toLowerCase() : value
    }));
  };

  const handleSubmitClick = () => {
    let hasEmpty = false;

    if (!articleData.token || !articleData.type || !articleData.status) {
      hasEmpty = true;
    }

    if (hasEmpty) {
      Modal.error({
        title: t("modal.error"),
        content: t("modal.requiredMessage"),
      });
    } else {
      Modal.confirm({
        title: t("modal.confirm"),
        icon: <ExclamationCircleOutlined />,
        content: t("modal.confirmSubmitMessage"),
        okText: t("modal.ok"),
        cancelText: t("modal.cancel"),
        onOk: () => handleSubmit(articleData)
      });
    }
  };

  return (
    <>
      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <BasicInput
            type="text"
            label={t('article.token')}
            placeholder="e.g. this-is-example"
            name="token"
            value={articleData.token}
            isRequired={true}
            handleChange={handleArticleDataChange}
            disabled={isEditArticle}
          />
        </Col>
      </Row>
      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <BasicSelect
            label={t('article.type')}
            placeholder=""
            name="type"
            value={articleData.type}
            options={articleTypes}
            isRequired={true}
            handleChange={handleArticleDataChange}
            disabled={true}
          />
        </Col>
      </Row>
      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <MultipleSelect
            label={t('article.platforms')}
            placeholder={t('article.selectPlatforms')}
            name="platforms"
            value={articleData.platforms}
            options={constants.platforms}
            handleChange={handleArticleDataChange}
          />
        </Col>
      </Row>
      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <BasicSelect
            label={t('article.status')}
            placeholder=""
            name="status"
            value={articleData.status}
            options={articleStatusTypes}
            isRequired={true}
            handleChange={handleArticleDataChange}
          />
        </Col>
      </Row>
      <Divider />

      <Button type="primary" loading={loading} onClick={handleSubmitClick}>
        {t("form.submit")}
      </Button>
    </>
  );
};

export default ArticleBasicData;
