import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import classes from '../input.module.css';

const { Search } = Input;

const SearchInput = ({
  label = "",
  placeholder = "",
  isRequired = false,
  handleSearch,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputLabel}>
        {isRequired ? <>{label} <span className={classes.requiredMessage}>{t('form.requiredField')}</span></> : <>{label}</>}
      </div>

      <Search
        placeholder={placeholder}
        onSearch={handleSearch}
        {...otherProps}
      />
    </div>
  );
};

export default SearchInput;
