import api from './api';

class newsApi {
  static checkNewsToken(data) {
    return api.post('/checkNewsToken', data);
  }

  static createNews(data) {
    return api.post('/createNews', data);
  }

  static getNewsList(data) {
    return api.post('/getNews', data);
  }

  static getEditNewsData(data) {
    return api.post('/getEditNewsData', data);
  }

  static updateNewsBasicData(data) {
    return api.post('/updateNewsBasicData', data);
  }

  static updateNewsContent(data) {
    return api.post('/updateNewsContent', data);
  }
}

export default newsApi;
