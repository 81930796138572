import api from './api';

class postApi {
  static createPost(data) {
    return api.post('/createPost', data);
  }

  static getPostList(data) {
    return api.post('/getPosts', data);
  }

  static getPostData(data) {
    return api.post('/getPostData', data);
  }

  static updatePost(data) {
    return api.post('/updatePost', data);
  }
}

export default postApi;
