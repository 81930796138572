import api from './api';

class articleApi {
  static checkArticleToken(data) {
    return api.post('/checkArticleToken', data);
  }

  static createArticle(data) {
    return api.post('/createArticle', data);
  }

  static getArticleList(data) {
    return api.post('/getArticles', data);
  }

  static getEditArticleData(data) {
    return api.post('/getEditArticleData', data);
  }

  static updateArticleBasicData(data) {
    return api.post('/updateArticleBasicData', data);
  }

  static updateArticleContent(data) {
    return api.post('/updateArticleContent', data);
  }
}

export default articleApi;
