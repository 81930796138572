import postApi from '../api/postApi';

export function atnCreatePost(data) {
  return postApi.createPost(data);
}

export function atnGetPostList(data) {
  return postApi.getPostList(data);
}

export function atnGetPostData(data) {
  return postApi.getPostData(data);
}

export function atnUpdatePost(data) {
  return postApi.updatePost(data);
}