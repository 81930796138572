import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicInput from 'components/input/BasicInput';

// import 'styles/login.css';

const CreateGame = () => {
  const { t } = useTranslation();

  const [createGameData, setCreateGameData] = useState({
    token: "",
  });

  const handleChange = e => {
    const { name, value } = e.target;

    setCreateGameData(prevState => ({
      ...prevState,
      [name]: value
    }))
  };

  return (
    <>
      <BasicInput
        type="text"
        label="Token (不能有空隔)"
        placeholder="e.g. mhw, nier-automate, the-last-of-us"
        name="token"
        value={createGameData.token}
        handleChange={handleChange}
      />

      <BasicInput
        type="text"
        label="遊戲名稱(原名)"
        placeholder="Token"
        name="token"
        value={createGameData.token}
        handleChange={handleChange}
      />
    </>
  );
};

export default CreateGame;
