import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Button, Dropdown, Menu, Divider } from 'antd';
import { SyncOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import BasicInput from 'components/input/BasicInput';
import TextAreaInput from 'components/input/TextAreaInput';
import BasicSelect from 'components/select/BasicSelect';
import MultipleSelect from 'components/select/MultipleSelect';
import UploadSingleImage from 'components/upload/UploadSingleImage';
import BasicCheckbox from 'components/checkbox/BasicCheckbox';

import constants from 'constants.json';

import classes from 'styles/news.module.css';
import 'styles/serverStyle.css';

const NewsContent = ({
  initContentData,
  loading,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const newsContentTypes = [];

  for (let i = 0; i < constants.newsContentTypes.length; i++) {
    newsContentTypes.push({
      "label": t(`newsContentType.${constants.newsContentTypes[i].label}`),
      "value": constants.newsContentTypes[i].value
    });
  }

  const languages = [];

  for (let i = 0; i < constants.languages.length; i++) {
    languages.push({
      "label": t(`languages.${constants.languages[i].label}`),
      "value": constants.languages[i].value
    });
  }

  const [newsData, setNewsData] = useState({
    token: "",
    newsDetailId: "",
    title: "",
    metaDescription: "",
    thumbnail: {
      file: null,
      url: "",
    },
    language: "",
  });
  const [contentElements, setContentElements] = useState([]);
  const [contentHtml, setContentHtml] = useState("");
  const [editElementIndex, setEditElementIndex] = useState(null);
  const [newsElement, setNewsElement] = useState({
    type: "",
    content: "",
    paragraphTitle: "",
    hasPlatformSign: false,
    platforms: [],
    images: [],
    imagesCommonCaption: "",
    videoSrc: "",
  });

  const [showCreateElementModal, setShowCreateElementModal] = useState(false);

  useEffect(() => {
    setContentElements(initContentData.contentData);
    setNewsData({
      token: initContentData.token,
      newsDetailId: initContentData.newsDetailId,
      title: initContentData.title,
      metaDescription: initContentData.metaDescription,
      thumbnail: initContentData.thumbnail,
      language: initContentData.language,
    });
  }, [initContentData]);

  const generatePlatforms = platforms => {
    let result = "";

    for (let i = 0; i < platforms.length; i++) {
      if (platforms[i] === "ps" || platforms[i] === "ps2" || platforms[i] === "ps3" || platforms[i] === "ps4" || platforms[i] === "ps5" || platforms[i] === "psv" || platforms[i] === "psp") {
        result += '<div class="gt-game-platform gt-game-platform-ps">';
      } else if (platforms[i] === "wii" || platforms[i] === "wiiU" || platforms[i] === "nds" || platforms[i] === "2ds" || platforms[i] === "3ds" || platforms[i] === "ns") {
        result += '<div class="gt-game-platform gt-game-platform-nintendo">';
      } else if (platforms[i] === "xbox" || platforms[i] === "xbox360" || platforms[i] === "xboxOne" || platforms[i] === "xboxSeriesX" || platforms[i] === "xboxSeriesS" || platforms[i] === "xboxSeriesS/X") {
        result += '<div class="gt-game-platform gt-game-platform-xbox">';
      } else if (platforms[i] === "pc") {
        result += '<div class="gt-game-platform gt-game-platform-pc">';
      } else if (platforms[i] === "ios") {
        result += '<div class="gt-game-platform gt-game-platform-ios">';
      } else if (platforms[i] === "android") {
        result += '<div class="gt-game-platform gt-game-platform-android">';
      } else {
        result += '<div class="gt-game-platform gt-game-platform-default">';
      }

      result += '<div class="gt-game-platform-text">' + t(`gamePlatform.${platforms[i]}`) + '</div></div>';
    }

    return result;
  };

  const updateContentHtml = () => {
    let newContentHtml = '<div class="gt-article-details-content">';

    for (let i = 0; i < contentElements.length; i++) {
      if (contentElements[i].type === "subtitle") {
        newContentHtml += '<div class="gt-article-details-subtitle">' + contentElements[i].content + '</div>';
      } else if (contentElements[i].type === "paragraph") {
        newContentHtml += '<div class="gt-article-details-paragraph">';

        if (contentElements[i].paragraphTitle) {
          newContentHtml += '<div class="gt-article-details-paragraph-title">' + contentElements[i].paragraphTitle + '</div>';
        }

        if (contentElements[i].hasPlatformSign) {
          newContentHtml += '<div>' + generatePlatforms(contentElements[i].platforms) + '</div>';
        }

        newContentHtml += '<div class="gt-article-details-paragraph-text">' + contentElements[i].content + '</div>';

        newContentHtml += '</div>';
      } else if (contentElements[i].type === "image") {
        if (contentElements[i].images.length > 0) {
          newContentHtml += '<div class="gt-article-details-image-container"><div class="gt-row">';

          let colNumber = contentElements[i].images.length;

          for (let j = 0; j < contentElements[i].images.length; j++) {
            newContentHtml += `<div class="gt-col-${colNumber}">`;

            newContentHtml += `<img class="gt-article-details-image" src="${contentElements[i].images[j].url}" alt="${contentElements[i].images[j].imageAltText}" />`;

            if (contentElements[i].images[j].caption) {
              newContentHtml += `<div class="gt-article-details-image-caption">${contentElements[i].images[j].caption}</div>`;
            }

            newContentHtml += '</div>';
          }

          newContentHtml += '</div>';

          if (contentElements[i].imagesCommonCaption) {
            newContentHtml += `<div class="gt-article-details-image-caption">${contentElements[i].imagesCommonCaption}</div>`;
          }

          newContentHtml += '</div>';
        }
      } else if (contentElements[i].type === "video") {
        newContentHtml += '<div class="gt-article-details-video-container"><div class="gt-article-details-video-wrapper">';

        newContentHtml += `<iframe class="gt-article-details-video" src="${contentElements[i].videoSrc}" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`;

        newContentHtml += '</div></div>';
      } else if (contentElements[i].type === "link") {
        if (contentElements[i].links.length > 0) {
          newContentHtml += '<div class="gt-article-details-links-container">';

          for (let j = 0; j < contentElements[i].links.length; j++) {
            newContentHtml += '<div class="gt-article-details-link">';

            newContentHtml += `<a href=${contentElements[i].links[j].url} target="_blank" rel="noopener noreferrer">${contentElements[i].links[j].linkText}</a>`;

            newContentHtml += '</div>';
          }

          newContentHtml += '</div>';
        }
      }
    }

    newContentHtml += '</div>';
    setContentHtml(newContentHtml);
  };

  useEffect(() => {
    updateContentHtml();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentElements]);

  const handleNewsDataChange = e => {
    const { name, value } = e.target;

    setNewsData(prevState => ({
      ...prevState,
      [name]: value
    }))
  };

  const handleThumbnailSelect = file => {
    setNewsData(prevState => ({
      ...prevState,
      thumbnail: {
        file: file,
        url: window.URL.createObjectURL(file),
        isNew: true,
      }
    }));
  };

  const handleEditElement = index => {
    setNewsElement(JSON.parse(JSON.stringify(contentElements[index])));
    setEditElementIndex(index);
    setShowCreateElementModal(true);
  };

  const handleElementMoveUp = index => {
    let currentElement = JSON.parse(JSON.stringify(contentElements[index]));
    let swapElement = JSON.parse(JSON.stringify(contentElements[index - 1]));

    setContentElements(prevState => {
      return prevState.map((item, i) => {
        if (i === index) {
          item = swapElement;
        } else if (i === (index - 1)) {
          item = currentElement;
        }

        return item;
      });
    });
  };

  const handleElementMoveDown = index => {
    let currentElement = JSON.parse(JSON.stringify(contentElements[index]));
    let swapElement = JSON.parse(JSON.stringify(contentElements[index + 1]));

    setContentElements(prevState => {
      return prevState.map((item, i) => {
        if (i === index) {
          item = swapElement;
        } else if (i === (index + 1)) {
          item = currentElement;
        }

        return item;
      });
    });
  };

  const handleElementMoveDelete = index => {
    setContentElements(prevState => {
      return prevState.filter((item, i) => i !== index)
    });
  };

  const openCreateElementModal = () => {
    setNewsElement({
      type: "",
      content: "",
      paragraphTitle: "",
      hasPlatformSign: false,
      platforms: [],
      images: [],
      imagesCommonCaption: "",
      videoSrc: "",
    });
    setEditElementIndex(null);
    setShowCreateElementModal(true);
  };

  const handleNewsElementDataChange = e => {
    const { name, value } = e.target;

    setNewsElement(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNewsElementTypeChange = e => {
    const { value } = e.target;

    if (value === "image") {
      setNewsElement({
        type: value,
        content: "",
        paragraphTitle: "",
        hasPlatformSign: false,
        platforms: [],
        images: [{
          file: null,
          url: "",
          imageAltText: "",
          caption: "",
          isNew: true,
        }],
        imagesCommonCaption: "",
        videoSrc: "",
      });
    } else {
      setNewsElement({
        type: value,
        content: "",
        paragraphTitle: "",
        hasPlatformSign: false,
        platforms: [],
        images: [],
        imagesCommonCaption: "",
        videoSrc: "",
      });
    }
  };

  const handleNewsElementHasPlatformSignChange = e => {
    const { value } = e.target;

    setNewsElement(prevState => ({
      ...prevState,
      hasPlatformSign: value,
      platforms: [],
    }));
  };

  const handleNewsElementImageAdd = () => {
    if (newsElement.images.length < 4) {
      setNewsElement(prevState => ({
        ...prevState,
        images: prevState.images.concat({
          file: null,
          url: "",
          imageAltText: "",
          caption: "",
          isNew: true,
        })
      }));
    }
  };

  const handleNewsElementImageDelete = index => {
    if (newsElement.images.length > 0) {
      setNewsElement(prevState => ({
        ...prevState,
        images: prevState.images.filter((image, i) => i !== index)
      }));
    }
  };

  const handleNewsElementImageUpload = (file, index) => {
    if (file) {
      setNewsElement(prevState => ({
        ...prevState,
        images: prevState.images.map((image, i) => {
          if (i === index) {
            image.file = file;
            image.url = window.URL.createObjectURL(file);
            image.isNew = true;
          }
  
          return image;
        })
      }));
    }
  };

  const handleNewsElementImageDataChange = (e, index) => {
    const { name, value } = e.target;

    setNewsElement(prevState => ({
      ...prevState,
      images: prevState.images.map((image, i) => {
        if (i === index) {
          image[name] = value;
        }

        return image;
      })
    }));
  };

  const handleOk = () => {
    if (editElementIndex || editElementIndex === 0) {
      setContentElements(prevState => {
        return prevState.map((item, index) => {
          if (index === editElementIndex) {
            item = newsElement;
          }

          return item;
        });
      });
    } else {
      setContentElements(prevState => {
        return prevState.concat(newsElement);
      });
    }

    setShowCreateElementModal(false);
  };

  const handleCancel = () => {
    setShowCreateElementModal(false);
    setNewsElement({
      type: "",
      content: "",
      paragraphTitle: "",
      hasPlatformSign: false,
      platforms: [],
      images: [],
      imagesCommonCaption: "",
      videoSrc: "",
    });
  };

  const handleSubmitClick = () => {
    let hasEmpty = false;

    if (!newsData.language || !newsData.title
      || !newsData.metaDescription || !newsData.thumbnail.url) {
      hasEmpty = true;
    }

    if (hasEmpty) {
      Modal.error({
        title: t("modal.error"),
        content: t("modal.requiredMessage"),
      });
    } else {
      Modal.confirm({
        title: t("modal.confirm"),
        icon: <ExclamationCircleOutlined />,
        content: t("modal.confirmSubmitMessage"),
        okText: t("modal.ok"),
        cancelText: t("modal.cancel"),
        onOk: () => handleSubmit(newsData, contentElements)
      });
    }
  };

  const ContentElementActionMenu = ({ index }) => (
    <Menu>
      <Menu.Item onClick={() => handleEditElement(index)}>
        {t('news.edit')}
      </Menu.Item>
      {index > 0 &&
        <Menu.Item onClick={() => handleElementMoveUp(index)}>
          {t('news.moveUp')}
        </Menu.Item>
      }
      {index < (contentElements.length - 1) &&
        <Menu.Item onClick={() => handleElementMoveDown(index)}>
          {t('news.moveDown')}
        </Menu.Item>
      }
      <Menu.Item onClick={() => handleElementMoveDelete(index)}>
        {t('news.delete')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <div className={classes.inputFormLeftContainer}>
            <BasicSelect
              label={t('news.language')}
              placeholder=""
              name="language"
              value={newsData.language}
              options={languages}
              handleChange={handleNewsDataChange}
              disabled={true}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className={classes.inputFormRightContainer}>
            <BasicInput
              type="text"
              label={t('news.title')}
              placeholder=""
              name="title"
              value={newsData.title}
              isRequired={true}
              handleChange={handleNewsDataChange}
            />
          </div>
        </Col>
      </Row>
      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <div className={classes.inputFormLeftContainer}>
            <UploadSingleImage
              label={t('news.thumbnail')}
              buttonText={t('news.clickToUpload')}
              url={newsData.thumbnail.url}
              isRequired={true}
              handleSelectImage={handleThumbnailSelect}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className={classes.inputFormRightContainer}>
            <TextAreaInput
              label={t('news.metaDescription')}
              placeholder=""
              name="metaDescription"
              value={newsData.metaDescription}
              isRequired={true}
              handleChange={handleNewsDataChange}
            />
          </div>
        </Col>
      </Row>

      <Row className={classes.inputFormRowContainer}>
        <Col span={12}>
          <div className={classes.inputFormLeftContainer}>
            <div className={classes.inputFormSubtitle}>
              {t('news.newsContent')}
            </div>

            {contentElements.map((item, index) => (
              <div key={index} className={classes.elementContainer}>
                <Row>
                  <Col span={20}>
                    <div className={classes.elementTitle}>{t(`newsContentType.${item.type}`)}</div>
                    {item.type !== "image" && item.type !== "video" &&
                      <div className={classes.elementSampleText}>{item.content}</div>
                    }
                  </Col>
                  <Col span={4} className={classes.elementActionContainer}>
                    <Dropdown overlay={<ContentElementActionMenu index={index} />} placement="bottomRight">
                      <Button>{t('news.action')}</Button>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            ))}

            <Button onClick={openCreateElementModal}>
              {t('news.addNewContent')}
            </Button>
          </div>
        </Col>
        <Col span={12}>
          <div className={classes.inputFormRightContainer}>
            <div className={classes.inputFormSubtitle}>
              {t('news.preview')} <SyncOutlined onClick={() => updateContentHtml()} />
            </div>
            <div className={classes.previewSection}>
              <div className={classes.previewContentContainer}>
                <div dangerouslySetInnerHTML={{__html: contentHtml}}/>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Divider />

      <Button type="primary" loading={loading} onClick={handleSubmitClick}>
        {t("form.submit")}
      </Button>

      <Modal
        keyboard={false}
        maskClosable={false}
        title={(editElementIndex || editElementIndex === 0) ? t('news.editContentTitle') : t('news.newContentTitle')}
        visible={showCreateElementModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('modal.ok')}
        cancelText={t('modal.cancel')}
      >
        <BasicSelect
          label={t('news.contentType')}
          placeholder=""
          name="type"
          value={newsElement.type}
          options={newsContentTypes}
          handleChange={handleNewsElementTypeChange}
        />

        {newsElement.type === "subtitle" &&
          <>
            <BasicInput
              type="text"
              label={t('news.content')}
              placeholder=""
              name="content"
              value={newsElement.content}
              handleChange={handleNewsElementDataChange}
            />
          </>
        }

        {newsElement.type === "paragraph" &&
          <>
            <BasicInput
              type="text"
              label={t('news.paragraphTitle')}
              placeholder=""
              name="paragraphTitle"
              value={newsElement.paragraphTitle}
              handleChange={handleNewsElementDataChange}
            />

            <BasicCheckbox
              label={t('news.platformSign')}
              name="hasPlatformSign"
              value={newsElement.hasPlatformSign}
              handleChange={handleNewsElementHasPlatformSignChange}
              hasPadding={false}
            />

            {newsElement.hasPlatformSign ?
              (
                <MultipleSelect
                  label=""
                  placeholder={t('news.selectPlatforms')}
                  name="platforms"
                  value={newsElement.platforms}
                  options={constants.platforms}
                  handleChange={handleNewsElementDataChange}
                />
              ) : (
                <div className={classes.elementInputPadding}></div>
              )
            }

            <TextAreaInput
              label={t('news.content')}
              placeholder=""
              name="content"
              value={newsElement.content}
              rows={6}
              handleChange={handleNewsElementDataChange}
            />
          </>
        }

        {newsElement.type === "image" &&
          <>
            <BasicInput
              type="text"
              label={t('news.commonCaption')}
              placeholder=""
              name="imagesCommonCaption"
              value={newsElement.imagesCommonCaption}
              handleChange={handleNewsElementDataChange}
            />

            {newsElement.images.map((image, index) => (
              <div key={index} className={classes.elementFormImageSection}>
                <div>
                  # {t('news.image')} {index + 1} <span><DeleteOutlined onClick={() => handleNewsElementImageDelete(index)} /></span>
                </div>
                <div className={classes.elementFormImageUploadButtonContainer}>
                  <UploadSingleImage
                    label=""
                    buttonText={t('news.clickToUpload')}
                    url={image.url}
                    handleSelectImage={file => handleNewsElementImageUpload(file, index)}
                  />
                </div>

                {image.url &&
                  <div>
                    <BasicInput
                      type="text"
                      label={t('news.imageAlt')}
                      placeholder=""
                      name="imageAltText"
                      value={image.imageAltText}
                      handleChange={e => handleNewsElementImageDataChange(e, index)}
                    />

                    <BasicInput
                      type="text"
                      label={t('news.caption')}
                      placeholder=""
                      name="caption"
                      value={image.caption}
                      handleChange={e => handleNewsElementImageDataChange(e, index)}
                    />
                  </div>
                }
              </div>
            ))}

            {newsElement.images.length < 4 &&
              <Button type="primary" onClick={() => handleNewsElementImageAdd()}>{t('news.addImage')}</Button>
            }
          </>
        }

        {newsElement.type === "video" &&
          <>
            <BasicInput
              type="text"
              label={t('news.videoSource')}
              placeholder=""
              name="videoSrc"
              value={newsElement.videoSrc}
              handleChange={handleNewsElementDataChange}
            />
          </>
        }
      </Modal>
    </>
  );
};

export default NewsContent;
