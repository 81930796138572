import React from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import classes from '../upload.module.css';

const UploadSingleImage = ({
  label = "",
  buttonText = "",
  url = "",
  isRequired = false,
  handleSelectImage
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputLabel}>
        {isRequired ? <>{label} <span className={classes.requiredMessage}>{t('form.requiredField')}</span></> : <>{label}</>}
      </div>
      <Upload
        accept="image/*"
        fileList={[]}
        beforeUpload={file => {
          if (file) {
            handleSelectImage(file);
          }

          return false;
        }}
      >
        <Button icon={<UploadOutlined />}>{buttonText || "Click to Upload"}</Button>
      </Upload>
      {url &&
        <img src={url} alt="" className={classes.previewImage} />
      }
    </div>
  );
};

export default UploadSingleImage;
