export function generateRandomString(length = 16) {
  var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var charactersLength = characters.length;
  var randomString = '';
  var randomNumber;

  for (var i = 0; i < length; i++) {
    randomNumber = Math.floor(Math.random() * charactersLength);
    randomString += characters.substr(randomNumber, 1);
  }

  return randomString;
}

export function generateRandomNumber(length = 8) {
  var characters = '0123456789';
  var charactersLength = characters.length;
  var randomString = '';
  var randomNumber;

  for (var i = 0; i < length; i++) {
    randomNumber = Math.floor(Math.random() * charactersLength);
    randomString += characters.substr(randomNumber, 1);
  }

  return randomString;
}

export function getImageDomain() {
  if (process.env.NODE_ENV === 'development') {
    return "https://gametribe-dev.oss-ap-southeast-1.aliyuncs.com/";
  } else {
    return "https://image.gametribe.co/";
  }
}

export function getImageOriginalDomain() {
  return "https://gametribe.oss-ap-southeast-1.aliyuncs.com/";
}

export function getImageFormat() {
  return ".jpg";
}
