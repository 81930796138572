import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { generateRandomString, getImageDomain, getImageFormat } from 'helpers/utils';
import PostForm from '../components/PostForm';
import { atnUploadPostImage } from 'actions/fileActions';
import { atnCreatePost } from 'actions/postActions';

const CreatePost = () => {
  const { t } = useTranslation();

  const initPostData = {
    content: "",
    images: [],
    // imageData: [],
    videoSource: "",
    links: [],
    status: "draft",
  };

  const [loading, setLoading] = useState(false);

  const uploadPostImage = imageData => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", imageData.image);
      formData.set("path", imageData.path);
      formData.set("isFirstImage", imageData.isFirstImage);

      atnUploadPostImage(formData)
      .then(uploadImageResult => {
        if (uploadImageResult.success) {
          resolve(uploadImageResult.data);
        } else {
          reject();
        }
      });
    });
  };

  const showErrorModal = message => {
    if (message === "Unauthorized") {

      // import login pop up and set state

      return Modal.error({
        title: t("modal.error"),
        content: message,
      });

    } else {
      return Modal.error({
        title: t("modal.error"),
        content: message,
      });
    }
  }

  const handleSubmit = postData => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 30000);

    let token = uuidv4();

    let imageUploadFuncArray = [];
    let images = [];
    let imageData = [];
    let imageName;

    for (let i = 0; i < postData.images.length; i++) {
      imageName = generateRandomString(8);

      if (postData.images[i].file) {
        imageUploadFuncArray.push(uploadPostImage({
          image: postData.images[i].file,
          path: "posts/" + token + "/" + imageName,
          isFirstImage: i === 0
        }));

        images.push({
          imageName: imageName,
          format: getImageFormat(),
          url: getImageDomain() + "posts/" + token + "/" + imageName + getImageFormat(),
          imageAltText: "",
        });

        imageData.push({
          original: getImageDomain() + "posts/" + token + "/" + imageName + "-hd" + getImageFormat(),
          thumbnail: getImageDomain() + "posts/" + token + "/" + imageName + getImageFormat(),
        });
      }
    }

    let createPostData = {
      token: token,
      content: postData.content,
      images: images,
      imageData: imageData,
      videoSource: postData.videoSource,
      links: postData.links,
      status: postData.status,
    };

    atnCreatePost(createPostData)
    .then(createPostResult => {
      if (createPostResult.success) {
        if (imageUploadFuncArray.length > 0) {
          Promise.all(imageUploadFuncArray)
          .then(() => {
            setLoading(false);

            Modal.success({
              content: t("modal.success"),
            });
          });
        } else {
          setLoading(false);

          Modal.success({
            content: t("modal.success"),
          });
        }
      } else {
        setLoading(false);
        showErrorModal(createPostResult.message);
      }
    });
  };

  return (
    <>
      <h1>{t('post.createPost')}</h1>
      <Divider />
      <PostForm
        initPostData={initPostData}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreatePost;