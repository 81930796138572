import axios from 'axios';

// const API_URL = "https://api.gametribe.co";
// const API_URL = "http://localhost:3000";
const API_URL = "";

class api {
  static post(path, data={}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        common: {
          'Authorization': sessionStorage.getItem('token'),
        }
      }
    };

    return axios.post(apiPath, data, config)
      .then(result => {
        let resultData = {
          success: true,
          message: "",
          data: {},
        };

        if (result && result.data && result.data.status) {
          if (result.data.status === 200) {
            resultData = {
              success: true,
              message: "",
              data: result.data.data,
            };

            return resultData;
          } else if (result.data.status === 400) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;

            // 500 server error
            // 400 missing data
            // 401 Unauthorized
            // 402 data exist
            // 403 invalid data
            // 404 data not found
            // 405 account disabled
          } else if (result.data.status === 401) {
            resultData = {
              success: false,
              message: "Unauthorized",
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 402) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 403) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 404) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 405) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          }
        }
      })
      .catch(error => {
        return {
          success: false,
          message: error.message,
          data: null,
        };
      });
  }

  static filePost(path, data={}) {
    const config = {
      headers: {
        post: {
          'Content-Type': 'multipart/form-data',
        },
        common: {
          'Authorization': sessionStorage.getItem('token'),
        }
      }
    };

    const apiPath = API_URL + path;

    return axios.post(apiPath, data, config)
      .then(result => {
        let resultData = {
          success: true,
          message: "",
          data: {},
        };

        if (result && result.data && result.data.status) {
          if (result.data.status === 200) {
            resultData = {
              success: true,
              message: "",
              data: result.data.data,
            };

            return resultData;
          } else if (result.data.status === 400) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;

            // 500 server error
            // 400 missing data
            // 401 Unauthorized
            // 402 data exist
            // 403 invalid data
            // 404 data not found
            // 405 account disabled
          } else if (result.data.status === 401) {
            resultData = {
              success: false,
              message: "Unauthorized",
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 402) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 403) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 404) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          } else if (result.data.status === 405) {
            resultData = {
              success: false,
              message: result.data.message,
              data: null,
            };
            
            return resultData;
          }
        }
      })
      .catch(error => {
        return {
          success: false,
          message: error.message,
          data: null,
        };
      });
  }
}

export default api;
