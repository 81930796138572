import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, Modal, Button } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import TextAreaInput from 'components/input/TextAreaInput';
import UploadSingleImage from 'components/upload/UploadSingleImage';

import constants from 'constants.json';

import classes from 'styles/post.module.css';
import 'styles/serverStyle.css';

const PostForm = ({
  initPostData,
  loading,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const newsStatusTypes = [];

  for (let i = 0; i < constants.newsStatusTypes.length; i++) {
    newsStatusTypes.push({
      "label": t(`newsStatusType.${constants.newsStatusTypes[i].label}`),
      "value": constants.newsStatusTypes[i].value
    });
  }

  const [postData, setPostData] = useState(initPostData);

  useEffect(() => {
    setPostData(initPostData);
  }, [initPostData]);

  const handlePostDataChange = e => {
    const { name, value } = e.target;

    setPostData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePostImageAdd = () => {
    setPostData(prevState => ({
      ...prevState,
      images: prevState.images.concat({
        imageName: "",
        format: "",
        file: null,
        url: "",
        isNew: true,
      })
    }));
  };

  const handlePostImageUpload = (file, index) => {
    if (file) {
      setPostData(prevState => ({
        ...prevState,
        images: prevState.images.map((image, i) => {
          if (i === index) {
            image.file = file;
            image.url = window.URL.createObjectURL(file);
            image.isNew = true;
          }
  
          return image;
        })
      }));
    }
  };

  const handlePostImageDelete = index => {
    if (postData.images.length > 0) {
      setPostData(prevState => ({
        ...prevState,
        images: prevState.images.filter((image, i) => i !== index)
      }));
    }
  };

  const handlePostLinkAdd = () => {
    setPostData(prevState => ({
      ...prevState,
      links: prevState.links.concat({
        url: "",
        linkText: "",
      })
    }));
  };

  const handlePostLinkDataChange = (e, index) => {
    const { name, value } = e.target;

    setPostData(prevState => ({
      ...prevState,
      links: prevState.links.map((link, i) => {
        if (i === index) {
          link[name] = value;
        }

        return link;
      })
    }));
  };

  const handlePostLinkDelete = index => {
    if (postData.links.length > 0) {
      setPostData(prevState => ({
        ...prevState,
        links: prevState.links.filter((link, i) => i !== index)
      }));
    }
  };

  const handleSubmitClick = () => {
    let hasEmpty = false;

    if (!postData.content && !postData.videoSource && postData.images.length === 0) {
      hasEmpty = true;
    }

    for (let i = 0; i < postData.links.length; i++) {
      if (!postData.links[i].url || !postData.links[i].linkText) {
        hasEmpty = true;
        break;
      }
    }

    if (hasEmpty) {
      Modal.error({
        title: t("modal.error"),
        content: t("modal.requiredMessage"),
      });
    } else {
      Modal.confirm({
        title: t("modal.confirm"),
        icon: <ExclamationCircleOutlined />,
        content: t("modal.confirmSubmitMessage"),
        okText: t("modal.ok"),
        cancelText: t("modal.cancel"),
        onOk: () => handleSubmit(postData)
      });
    }
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <BasicSelect
            label={t('post.status')}
            placeholder=""
            name="status"
            value={postData.status}
            options={newsStatusTypes}
            isRequired={true}
            handleChange={handlePostDataChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextAreaInput
            label={t('post.content')}
            placeholder=""
            name="content"
            value={postData.content}
            rows={12}
            handleChange={handlePostDataChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <BasicInput
            type="text"
            label={t('post.videoSource')}
            placeholder=""
            name="videoSource"
            value={postData.videoSource}
            handleChange={handlePostDataChange}
          />
        </Col>
      </Row>

      <Row className={classes.inputContainer}>
        <Col span={12}>
          <div>
            {t('post.images')}
          </div>

          {postData.images.map((image, index) => (
            <div key={index} className={classes.imageSection}>
              <div>
                # {t('post.image')} {index + 1} <span><DeleteOutlined onClick={() => handlePostImageDelete(index)} /></span>
              </div>
              <div className={classes.imageUploadButtonContainer}>
                <UploadSingleImage
                  label=""
                  buttonText={t('post.clickToUpload')}
                  url={image.url}
                  handleSelectImage={file => handlePostImageUpload(file, index)}
                />
              </div>
            </div>
          ))}

          <Button type="primary" onClick={() => handlePostImageAdd()}>{t('post.addImage')}</Button>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <div>
            {t('post.links')}
          </div>

          {postData.links.map((link, index) => (
            <div key={index} className={classes.linkSection}>
              <div>
                # {t('post.link')} {index + 1} <span><DeleteOutlined onClick={() => handlePostLinkDelete(index)} /></span>
              </div>

              <BasicInput
                type="text"
                label={t('post.url')}
                placeholder=""
                name="url"
                value={link.url}
                isRequired={true}
                handleChange={e => handlePostLinkDataChange(e, index)}
              />

              <BasicInput
                type="text"
                label={t('post.linkText')}
                placeholder=""
                name="linkText"
                value={link.linkText}
                isRequired={true}
                handleChange={e => handlePostLinkDataChange(e, index)}
              />
            </div>
          ))}

          <Button type="primary" onClick={() => handlePostLinkAdd()}>{t('post.addLink')}</Button>
        </Col>
      </Row>

      <Divider />

      <Button type="primary" loading={loading} onClick={handleSubmitClick}>
        {t("form.submit")}
      </Button>
    </>
  );
};

export default PostForm;