import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import {
  HomeOutlined,
  VideoCameraFilled,
  BookOutlined,
} from '@ant-design/icons';

const { SubMenu, Item } = Menu;

const AdminSideMenu = ({
  history,
}) => {
  const { t } = useTranslation();
  const selectedItem = history.location.pathname.split('/')[1];

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[selectedItem]}>
      <Item key="dashboard" icon={<HomeOutlined />}>
        <Link to="/dashboard">{t('menu.dashboard')}</Link>
      </Item>
      <SubMenu key="game" icon={<VideoCameraFilled />} title={t('menu.game')}>
        <Item key="gameList">
          <Link to="/gameList">{t('menu.gameList')}</Link>
        </Item>
        <Item key="createGame">
          <Link to="/createGame">{t('menu.createGame')}</Link>
        </Item>
        <Item key="editGame">
          <Link to="/editGame">{t('menu.editGame')}</Link>
        </Item>
      </SubMenu>
      <SubMenu key="news" icon={<BookOutlined />} title={t('menu.news')}>
        <Item key="newsList">
          <Link to="/newsList">{t('menu.newsList')}</Link>
        </Item>
        <Item key="createNews">
          <Link to="/createNews">{t('menu.createNews')}</Link>
        </Item>
        <Item key="editNews">
          <Link to="/editNews">{t('menu.editNews')}</Link>
        </Item>
      </SubMenu>
      <SubMenu key="post" icon={<BookOutlined />} title={t('menu.post')}>
        <Item key="postList">
          <Link to="/postList">{t('menu.postList')}</Link>
        </Item>
        <Item key="createPost">
          <Link to="/createPost">{t('menu.createPost')}</Link>
        </Item>
        <Item key="editPost">
          <Link to="/editPost">{t('menu.editPost')}</Link>
        </Item>
      </SubMenu>
      <SubMenu key="article" icon={<BookOutlined />} title={t('menu.article')}>
        <Item key="articleList">
          <Link to="/articleList">{t('menu.articleList')}</Link>
        </Item>
        <Item key="createArticle">
          <Link to="/createArticle">{t('menu.createArticle')}</Link>
        </Item>
        <Item key="editArticle">
          <Link to="/editArticle">{t('menu.editArticle')}</Link>
        </Item>
      </SubMenu>
    </Menu>
  );
}

export default withRouter(AdminSideMenu);