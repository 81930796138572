import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import auth from 'auth/authenticator';
import i18n from 'i18n';
import constants from 'constants.json';

const { Header } = Layout;

const HeaderSection = ({
  history
}) => {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("language") || constants.defaultLanguage);

  const changeLanguage = lng => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const logout = () => {
    auth.logOut();
    history.push("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => changeLanguage("zh-HK")}>
          {t("languages.zh-HK")}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => changeLanguage("zh-CN")}>
          {t("languages.zh-CN")}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => changeLanguage("en")}>
          {t("languages.en")}
        </div>
      </Menu.Item>
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => logout()}>
          {t("profileMenu.logout")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="site-header site-layout-background">
      <div className="header-select-language-container">
        <Dropdown overlay={menu} placement="bottomRight">
          <div>{t(`languages.${currentLanguage}`)} <DownOutlined /></div>
        </Dropdown>
      </div>
      <Dropdown overlay={userMenu} placement="bottomRight" className="header-user-avatar-container">
        <img src="https://image.gametribe.co/pikachu.jpg" alt="" className="header-user-avatar" />
        {/* <Avatar icon={<UserOutlined />} /> */}
      </Dropdown>
    </Header>
  );
}

export default withRouter(HeaderSection);